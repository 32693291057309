import React from 'react';
import { themeContext } from './../context/theme-context'
import loadable from '@loadable/component'
import NavBarGeneric from './NavBar'
const FooterContainer = loadable(() => import('./Footer'))
import ScrollToTop from './ScrollToTop'

const Cgv = () => {
    return (
        <themeContext.Consumer>
        {({ colorTheme, colorThemeHexa, toggleTheme }) => (
            <>
                <ScrollToTop />
                <NavBarGeneric colorStroke={colorThemeHexa} colorTheme={colorTheme} />
      <div style={{padding: '80px'}} className="mentions_legales hero is-twitter">
      <p dir="ltr" style={{textAlign: 'left'}}><span style={{display: 'none'}}>&nbsp;</span>&nbsp;</p>
        <p dir="ltr" style={{textAlign: 'left'}}>&nbsp;</p>
        <p dir="ltr" style={{textAlign: 'left'}}><span style={{fontSize: '20px'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Conditions Générales de Vente&nbsp;de produits en ligne entre professionnels&nbsp;</span></strong></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Préambule </span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales de vente s'appliquent à toutes les ventes conclues sur le site Internet&nbsp;<span className="boiler-field">onfirstdigital</span>.</span></span></p>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le site Internet&nbsp;<span className="boiler-field">onfirstdigital</span>&nbsp;est un service de : </span></span></p>
        <ul dir="ltr">
          <li><span style={{fontFamily: 'assistantBold'}}>{/**/}<span rl-text="content6">la société</span>{/**/}{/**/}&nbsp;<span style={{fontStyle: 'normal'}}><span className="boiler-field">Onfirstdigital</span></span>​​​​​​&nbsp;</span></li>
          <li><span style={{fontFamily: 'assistantBold'}}>située&nbsp;<span className="boiler-field">33 rue LaFayette</span>&nbsp;<span className="boiler-field">75009</span>&nbsp;<span className="boiler-field">France</span></span></li>
          <li><span style={{fontFamily: 'assistantBold'}}>adresse du site :&nbsp;<span className="boiler-field">https://www.onfirstdigital.com</span></span></li>
          <li><span style={{fontFamily: 'assistantBold'}}>adresse e-mail :&nbsp;<span className="boiler-field">contact@onfirstdigital.com</span></span></li>
          <li><span style={{fontFamily: 'assistantBold'}}>téléphone :&nbsp;<span className="boiler-field">07 80 97 50 94</span></span></li>
        </ul>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le site Internet <span className="boiler-field">onfirstdigital</span>&nbsp;commercialise les produits suivants :&nbsp;<span className="boiler-field">Création de site internet, développement, sécurité et conseil</span>.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le client déclare avoir pris connaissance et avoir accepté les conditions générales de vente antérieurement à la passation de sa commande. La&nbsp;validation de la commande vaut donc&nbsp;acceptation des conditions générales de vente.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 1 - Principes</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales expriment l'intégralité des obligations des parties. Elles constituent le socle unique de la relation commerciale entre les parties, et, en ce sens, l'acheteur est réputé les accepter sans réserve.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales de vente prévalent sur tout autre document, et notamment sur toutes conditions générales d'achat. Elles s'appliquent, sans restriction ni réserve, à tous les services rendus par le vendeur auprès des acheteurs professionnels.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le vendeur et l'acheteur conviennent que les présentes conditions générales régissent exclusivement leur relation. Le vendeur se réserve le droit de modifier ponctuellement ses conditions générales. Elles seront applicables dès leur mise en ligne.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si une condition de vente venait à faire défaut, elle serait considérée être régie par les usages en vigueur dans le secteur de la vente à distance dont les sociétés ont siège en France.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales de vente sont communiquées à tout acheteur qui en fait la demande, afin de lui permettre de passer commande.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales de vente sont applicables jusqu'au&nbsp;<span className="boiler-field">31 août 2025</span>.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 2 - Contenu</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales ont pour objet de définir les droits et obligations des parties dans le cadre de la vente en ligne des biens et produits proposés par le vendeur à l'acheteur.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions ne concernent que les achats effectués sur ce site internet et livrés exclusivement en métropole et en Corse. Pour toute livraison dans les DOM-TOM ou hors de France, il convient de le signaler pour obtenir un devis spécifique.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 3 - La commande</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur passe sa commande en ligne, à partir du catalogue en ligne et au moyen du formulaire qui figure sur le site.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Pour que la commande soit validée, l'acheteur devra accepter, en cliquant à l'endroit indiqué sur le site, les présentes conditions générales. Son acceptation entraînera l'envoi d'un mail de confirmation de la part du vendeur, conformément aux conditions décrites ci-dessous.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur devra choisir l'adresse et le mode de livraison. Le paiement se fait par prélèvement bancaire.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Toute commande vaut acceptation des prix et descriptions des produits disponibles à la vente. Toute contestation sur ce point interviendra dans le cadre d'un éventuel échange et des garanties ci-dessous mentionnées.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Dans certains cas, notamment défaut de paiement, adresse erronée ou autre problème sur le compte de l'acheteur, le vendeur se réserve le droit de bloquer la commande de l'acheteur jusqu'à la résolution du problème.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En cas d'indisponibilité d'un produit commandé, l'acheteur en sera informé par courrier électronique.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'annulation de la commande de ce produit et son éventuel remboursement seront alors effectués, le reste de la commande demeurant ferme et définitif.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Pour toute question relative au suivi d'une commande, l'acheteur peut :</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>-&nbsp;<span style={{fontStyle: 'normal'}}>appeler le numéro suivant : <span className="boiler-field">07 80 97 50 94</span>&nbsp;(coût d'un appel local),&nbsp;&nbsp;aux jours et horaires suivants :&nbsp;<span className="boiler-field">du lundi au vendredi de 8h à 20h</span></span>,</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>- envoyer un e-mail à l’adresse suivante : <span className="boiler-field">contact@onfirstdigital.com</span></span>.</span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 4 - Signature électronique</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>La fourniture en ligne des coordonnées bancaires de l'acheteur et la validation finale de la commande vaudront preuve de l'accord de l'acheteur.&nbsp;Cela permettra au vendeur d’obtenir l’exigibilité des sommes dues au titre du bon de commande &nbsp;et cela vaudra signature et acception expresse de toutes les opérations effectuées.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En cas d'utilisation frauduleuse des coordonnées bancaires, l'acheteur est invité, dès le constat de cette utilisation, à contacter le vendeur en l'appelant au numéro suivant : <span className="boiler-field">07 80 97 50 94</span>, ou en lui envoyant un e-mail à l'adresse suivante :&nbsp;<span className="boiler-field">contact@onfirstdigital.com</span>.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 5 - Confirmation de la commande</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les informations contractuelles feront l'objet d'une confirmation par voie d'e-mail au plus tard au moment de la livraison ou à défaut, à l'adresse indiquée par l'acheteur sur le&nbsp;bon de commande.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 6 - Preuve de la transaction</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les registres informatisés, conservés dans les systèmes informatiques du vendeur dans des conditions raisonnables de sécurité, seront considérés comme les preuves des communications, des commandes et des paiements intervenus entre les parties. L'archivage des bons de commande et des factures est effectué sur un support fiable et durable pouvant être produit à titre de preuve.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 7 - Informations sur les produits</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les produits régis par les présentes conditions générales sont ceux qui figurent sur le site internet du vendeur et qui sont indiqués comme vendus et expédiés par le vendeur. Ils sont proposés dans la limite des stocks disponibles.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les produits sont décrits et présentés avec la plus grande exactitude possible. Toutefois, si des erreurs ou omissions ont pu se produire quant à cette présentation, la responsabilité du vendeur ne pourrait être engagée.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les photographies des produits ne sont pas contractuelles.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 8 - Prix</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'italic'}}>Calcul</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le vendeur se réserve le droit de modifier ses prix à tout moment mais s'engage à appliquer les tarifs en vigueur indiqués au moment de la commande, sous réserve de disponibilité à cette date. Les prix sont indiqués en euros. Ils ne tiennent pas compte des frais de livraison, facturés en supplément, et indiqués avant la validation de la commande. Les prix tiennent compte de la TVA applicable au jour de la commande et tout changement du taux applicable TVA sera automatiquement répercuté sur le prix des produits de la boutique en ligne. </span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le paiement de la totalité du prix doit être réalisé lors de la commande. À aucun moment, les sommes versées ne pourront être considérées comme des arrhes ou des acomptes. </span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si une ou plusieurs taxes ou contributions, notamment environnementales, venaient à être créées ou modifiées, en hausse comme en baisse, ce changement pourra être répercuté sur le prix de vente des produits.</span></span></p>
        <div>
          <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'italic'}}>Acompte</span></span></p>
          <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Toute commande peut donner lieu au versement d'un acompte, dont les modalités seront fixées au moment de la vente. Hors cas de force majeure, toute annulation de la commande par l'acheteur à compter de <span className="boiler-field">10 jours</span>&nbsp;ne pourra donner lieu au remboursement de cet acompte prévu.</span></span></p>
        </div>
        <div>
          <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'italic'}}>Réduction de prix</span></span></p>
          <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur pourra bénéficier des remises et ristournes particulières le cas échéant, selon les conditions particulières précisées lors de la commande.</span></span></p>
        </div>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>&nbsp;</span><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 9 - Mode de paiement</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'italic'}}>Règlement</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Il s'agit d'une commande avec obligation de paiement, ce qui signifie que la passation de la commande implique un règlement de l'acheteur. Le règlement de la commande se fait uniquement par prélèvement sur le compte bancaire de l'acheteur. L'acheteur doit rentrer ses coordonnées bancaires à l'endroit prévu et confirmer cette saisie par l'ajout de son RIB informatique en pièce jointe du bon de commande. Le vendeur se réserve le droit de suspendre toute gestion de commande et toute livraison en cas de refus d'autorisation de paiement de la part des organismes officiellement accrédités ou en cas de non-paiement. Le vendeur se réserve notamment le droit de refuser d'effectuer une livraison ou d'honorer une commande émanant d'un acheteur qui n'aurait pas réglé totalement ou partiellement une commande précédente ou avec lequel un litige de paiement serait en cours d'administration. Le vendeur a mis en place une procédure de vérification des commandes destinée à assurer qu'aucune personne n'utilise les coordonnées bancaires d'une autre personne à son insu. Dans le cadre de cette vérification, il pourra être demandé à l'acheteur d'adresser par fax au vendeur une copie d'une pièce d'identité ainsi qu'un justificatif de domicile. La commande ne sera alors validée qu'après réception et vérification par le vendeur des pièces envoyées.</span></span></p>
        <div>
          <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{display: 'none'}}>&nbsp;</span><span style={{fontStyle: 'normal'}}>Le prix est payable en totalité et en un seul versement à réception de la commande. La date de paiement sera mentionnée sur la facture adressée à l'acheteur.</span></span></p>
          <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'italic'}}>Retard de paiement</span></span></p>
          <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Tout retard de paiement entraînera l'exigibilité immédiate de la totalité des sommes dues au vendeur par l'acheteur, sans préjudice de toute autre action que le vendeur serait en droit d'intenter, à ce titre, à l'encontre de l'acheteur.</span></span></p>
        </div>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}>&nbsp;<span style={{display: 'none'}}>&nbsp;</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 10 - Disponibilité des produits</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Sauf cas de force majeure ou lors des périodes de fermeture clairement annoncées sur la page d'accueil du site, les délais d'expédition seront, dans la limite des stocks disponibles, ceux indiqués ci-dessous. Les délais d'expédition courent à compter de la date d'enregistrement de la commande indiquée sur le mail de confirmation de la commande.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Pour toute livraison en France (métropole et Corse), le délai est de <span className="boiler-field">La livraison doit intervenir au plus tard 30 jours après la commande en ligne.</span>&nbsp;à compter du jour suivant celui où l'acheteur a passé sa commande.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Pour toute livraison dans les DOM-TOM ou hors de France, il convient de le signaler pour obtenir un devis spécifique.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En cas de retard, la responsabilité du vendeur ne pourra être engagée, et ce, pour quelque cause que ce soit. Par conséquent, aucune demande d'indemnisation, de quelque nature que ce soit, ne pourra être réclamée par l'acheteur.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En cas d'indisponibilité du produit commandé, l'acheteur en sera informé au plus tôt et aura la possibilité d'annuler sa commande. L'acheteur aura alors le choix de demander soit le remboursement des sommes versées dans les 30 jours au plus tard de leur versement, soit l'échange du produit.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 11 - Modalités de livraison</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>La livraison n'est faite qu'après confirmation du paiement par l'organisme bancaire du vendeur.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Elle est fournie dans le délai précisé à l’article 10, à compter de la réception par le vendeur du bon de commande.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Tout retard supérieur de&nbsp;<span className="boiler-field">2  mois</span><span style={{fontStyle: 'normal'}}> </span>pourra entraîner la résolution de la vente. Les acomptes&nbsp;versés ou le règlement effectué à la commande seront alors restitués à l'acheteur. Le règlement effectué à la commande sera&nbsp;alors restitué à l'acheteur.&nbsp;</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En cas de non-respect des conditions de paiement figurant ci-dessus, le vendeur pourra suspendre ou annuler la vente.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les produits sont livrés à l'adresse indiquée par l'acheteur sur le bon de commande, l'acheteur devra veiller à son exactitude. Tout colis renvoyé au vendeur à cause d'une adresse de livraison erronée ou incomplète sera réexpédié aux frais de l'acheteur. L'acheteur peut, à sa demande, obtenir l'envoi d'une facture à l'adresse de facturation et non à l'adresse de livraison, en validant l'option prévue à cet effet sur le bon de commande.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si l'acheteur est absent le jour de la livraison, le livreur laissera un avis de passage dans la boîte aux lettres, qui permettra de retirer le colis à l'endroit et pendant le délai indiqué.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si au moment de la livraison, l'emballage d'origine est abîmé, déchiré, ouvert, l'acheteur doit alors vérifier l'état des articles. S'ils ont été endommagés, l'acheteur doit impérativement refuser le colis et noter une réserve sur le bordereau de livraison (colis refusé car ouvert ou endommagé).</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur doit indiquer sur le bon de livraison et sous forme de réserves manuscrites accompagnées de sa signature toute anomalie concernant la livraison (avarie, produit manquant par rapport au bon de livraison, colis endommagé, produits cassés...).</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Cette vérification est considérée comme effectuée dès lors que l'acheteur, ou une personne autorisée par lui, a signé le bon de livraison.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur devra alors confirmer par courrier recommandé ces réserves au transporteur au plus tard dans les deux jours ouvrables suivant la réception du ou des article(s) et transmettre une copie de ce courrier par fax ou simple courrier au vendeur à l'adresse indiquée dans les mentions légales du site.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si les produits nécessitent d'être renvoyés au vendeur, ils doivent faire l'objet d'une demande de retour auprès du vendeur dans les 14 jours ouvrables&nbsp;suivant la livraison. Toute réclamation formulée hors de ce délai ne pourra être acceptée. Le retour du produit ne pourra être accepté que pour les produits dans leur état d'origine (emballage, accessoires, notice...).</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 12 - Erreurs de livraison</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur devra formuler auprès du vendeur le jour même de la livraison ou au plus tard le premier jour ouvré suivant la livraison, toute réclamation d'erreur de livraison et/ou de non-conformité des produits en nature ou en qualité par rapport aux indications figurant sur le bon de commande. Toute réclamation formulée au-delà de ce délai sera rejetée.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>La réclamation pourra être faite, au choix de l'acheteur :</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>- &nbsp;en contactant le vendeur au numéro de téléphone suivant : <span className="boiler-field">07 80 97 50 94</span>&nbsp;;</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}><span style={{fontStyle: 'normal'}}>- &nbsp;en utilisant l'adresse&nbsp;électronique suivante : <span className="boiler-field">contact@onfirstdigital.com</span></span>.&nbsp;</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Toute réclamation non effectuée dans les règles définies ci-dessus et dans les délais impartis ne pourra être prise en compte et dégagera le vendeur de toute responsabilité vis-à-vis de l'acheteur.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>À réception de la réclamation, le vendeur attribuera un numéro d'échange du ou des produit(s) concerné(s) et le communiquera par e-mail à l'acheteur. L'échange d'un produit ne peut avoir lieu qu'après l'attribution du numéro d'échange.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En cas d'erreur de livraison ou d'échange, tout produit à échanger ou à rembourser devra être retourné au vendeur dans son ensemble et dans son emballage d'origine, en Colissimo Recommandé, à l'adresse suivante : <span className="boiler-field">30 RUE PERQUEL</span>, <span className="boiler-field">95160</span>&nbsp;<span className="boiler-field">MONTMORENCY</span>.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les frais de retour sont à la charge du vendeur.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 13 - Garantie des produits</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le vendeur garantit l'acheteur contre tout défaut de conformité des services et tout vice caché, provenant d'un défaut de conception ou de fourniture desdits services à l'exclusion de toute négligence ou faute de l'acheteur.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>En tout état de cause, au cas où la responsabilité du vendeur serait retenue, la garantie du vendeur serait limitée au montant HT payé par l'acheteur pour l'achat du bien.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 14 - Droit de rétractation</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>L'acheteur étant un professionnel achetant dans le cadre et pour les besoins de sa profession, il n'y a pas lieu d'appliquer le droit de rétractation prévu par le code de la consommation.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 15 - Force majeure</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Toutes circonstances indépendantes de la volonté des parties, empêchant l'exécution dans des conditions normales de leurs obligations, sont considérées comme des causes d'exonération des obligations des parties et entraînent leur suspension.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>La partie qui invoque les circonstances visées ci-dessus doit avertir immédiatement l'autre partie de leur survenance, ainsi que de leur disparition.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Seront considérés comme cas de force majeure tous faits ou circonstances irrésistibles, extérieurs aux parties, imprévisibles, inévitables, indépendants de la volonté des parties et qui ne pourront être empêchés par ces dernières, malgré tous les efforts raisonnablement possibles. De façon expresse, sont considérés comme cas de force majeure ou cas fortuits, outre ceux habituellement retenus par la jurisprudence des cours et des tribunaux français : le blocage des moyens de transports ou d'approvisionnements, tremblements de terre, incendies, tempêtes, inondations, foudre, l'arrêt des réseaux de télécommunication ou difficultés propres aux réseaux de télécommunication externes aux clients.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les parties se rapprocheront pour examiner l'incidence de l'événement et convenir des conditions dans lesquelles l'exécution du contrat sera poursuivie. Si le cas de force majeure a une durée supérieure à trois mois, les présentes conditions générales pourront être résiliées par la partie lésée.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 16 - Non-validation partielle</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si une ou plusieurs clauses des présentes conditions générales sont tenues pour non valides ou déclarées telles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres clauses garderont toute leur force et leur portée.</span></span></p>
        <p>&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 17 - Non-renonciation</span></strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Le fait pour l'une des parties de ne pas se prévaloir d'un manquement par l'autre partie à l'une quelconque des obligations visées dans les présentes conditions générales ne saurait être interprété pour l'avenir comme une renonciation à l'obligation en cause.</span></span></p>
        <p>&nbsp;</p>
        <p><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal'}}>Article 18 - Loi applicable</span></strong></span></p>
        <p>&nbsp;</p>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les présentes conditions générales sont soumises à l'application du droit français.</span></span></p>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Les parties s'engagent à rechercher une solution amiable à tout différend qui pourrait naître de l'interprétation ou de l'exécution du Contrat.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal'}}>Si elles n'y parviennent pas, les parties soumettront le litige au tribunal de Commerce.</span></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><strong style={{ color:'#ffffff', textTransform:'uppercase'}}>Article 19- Collecte des données personnelles</strong></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><em><u><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Données collectées:</span></strong></u></em></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><em><span style={{fontStyle: 'normal', textAlign: 'left'}}>Les données à caractère personnel qui sont collectées sur ce site&nbsp; sont les suivantes&nbsp;:</span></em></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Ouverture de compte:</b><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}> </b><span style={{fontStyle: 'normal', textAlign: 'left'}}>lors de la création du compte de l'utilisateur, ses {/**/}<span rl-text="content1">nom,</span>&nbsp;{/**/}<span rl-text="content2">prénom,</span>&nbsp;{/**/}<span rl-text="content3">adresse électronique;</span>&nbsp;{/**/}&nbsp;{/**/}<span rl-text="content5">adresse postale;</span>&nbsp;{/**/}</span>;</span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Connexion : </b><span style={{fontStyle: 'normal', textAlign: 'left'}}>lors de la connexion de l'utilisateur au site web, celui-ci enregistre, notamment, ses nom, prénom, données de connexion, d'utilisation, de localisation et ses données relatives au paiement.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Profil:</b><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}> </b><span style={{fontStyle: 'normal', textAlign: 'left'}}>l'utilisation des prestations prévues sur le site web&nbsp;permet de renseigner un profil, pouvant comprendre une adresse et un numéro de téléphone.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Paiement: </b><span style={{fontStyle: 'normal', textAlign: 'left'}}>dans le cadre du paiement des produits et prestations proposés sur le site web, celui-ci enregistre des données financières relatives au compte bancaire ou à la carte de crédit de l'utilisateur.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Communication: </b><span style={{fontStyle: 'normal', textAlign: 'left'}}>lorsque le site web&nbsp;est utilisé&nbsp;pour communiquer avec d'autres membres, les données concernant les communications de l'utilisateur font l'objet d'une conservation temporaire.</span></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><b style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Cookies :</b><span style={{fontStyle: 'normal', textAlign: 'left'}}>&nbsp;les cookies sont utilisés, dans le cadre de l'utilisation du site. L'utilisateur a la possibilité de désactiver les cookies à partir des paramètres de son navigateur.</span></span></p>
        <p dir="ltr">&nbsp;</p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><u><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Utilisation des données personnelles</span></strong></u></span></p>
        <p dir="ltr"><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services du site web, leur amélioration et le maintien d'un environnement sécurisé. Plus précisément, les utilisations sont les suivantes&nbsp;:</span></span></p>
        <div style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;accès et utilisation du site web&nbsp;par l'utilisateur&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;gestion du fonctionnement et optimisation du site web&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;organisation des conditions d'utilisation des Services de paiement&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;vérification, identification et authentification des données transmises par l'utilisateur&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;proposition à l'utilisateur de la possibilité de communiquer avec d'autres utilisateurs du site web&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;mise en oeuvre d'une assistance utilisateurs&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;personnalisation des services en affichant des publicités en fonction de l'historique de navigation de l'utilisateur, selon ses préférences&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents de sécurité&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;gestion des éventuels litiges avec les utilisateurs&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;envoi d'informations commerciales et publicitaires, en fonction des préférences de l'utilisateur.<br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
              <br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
              <strong style={{ color:'#ffffff', textTransform:'uppercase'}}><u><span style={{fontStyle: 'normal', textAlign: 'left'}}>Partage des données personnelles avec des tiers</span></u></strong></span></p>
        </div>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Les données personnelles peuvent être partagées avec des sociétés tierces, dans les cas suivants&nbsp;:</span></span></p>
        <div style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;quand l'utilisateur utilise les services de paiement, pour la mise en oeuvre de ces services, le site web&nbsp;est en relation avec des sociétés bancaires et financières tierces avec lesquelles elle a passé des contrats&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;lorsque l'utilisateur publie, dans les zones de commentaires libres du site web, des informations accessibles au public&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;quand l'utilisateur autorise le site web d'un tiers à accéder à ses données&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;quand le site web&nbsp;recourt aux services de prestataires pour fournir l'assistance utilisateurs, la publicité et les services de paiement. Ces prestataires disposent d'un accès limité aux données de l'utilisateur, dans le cadre de l'exécution de ces prestations, et ont une obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation applicable en matière protection des données à caractère personnel&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;si la loi l'exige, le site web&nbsp;peut effectuer la transmission de données pour donner suite aux réclamations présentées contre le site web&nbsp;et se conformer aux procédures administratives et judiciaires&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;si le site web&nbsp;est impliquée dans une opération de fusion, acquisition, cession d'actifs ou procédure de redressement judiciaire, elle pourra être amenée à céder ou partager tout ou partie de ses actifs, y compris les données à caractère personnel. Dans ce cas, les utilisateurs seraient informés, avant que les données à caractère personnel ne soient transférées à une tierce partie.<br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
              <br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
              <u><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Sécurité et confidentialité</span></strong></u></span></p>
        </div>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Le site web&nbsp;met en oeuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois, il est à signaler qu'internet n'est pas un environnement complètement sécurisé et le site web&nbsp;ne peut pas garantir la sécurité de la transmission ou du stockage des informations sur internet.</span></span></p>
        <p><br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
          <span style={{fontFamily: 'assistantBold'}}><u><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>Mise en oeuvre des droits des utilisateurs</span></strong></u></span></p>
        <p><span style={{fontFamily: 'assistantBold'}}><span style={{fontStyle: 'normal', textAlign: 'left'}}>En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants, qu'ils peuvent faire valoir à l'adresse suivante&nbsp;<span className="boiler-field">contact@onfirstdigital.com</span>&nbsp;:</span></span></p>
        <div style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;ils peuvent mettre à jour ou supprimer les données qui les concernent en se connectant à leur compte et en configurant les paramètres de ce compte&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;ils peuvent supprimer leur compte, en écrivant à l'adresse électronique suivante&nbsp;<span style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}><span className="boiler-field">contact@onfirstdigital.com</span></span>. Il est à noter que les informations partagées avec d'autres utilisateurs, comme les publications sur les forums, peuvent rester visibles du public sur le site web, même après la suppression de leur compte&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant, en écrivant à l'adresse électronique suivante&nbsp;: <span style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}><span className="boiler-field">contact@onfirstdigital.com</span></span>. Dans ce cas, avant la mise en oeuvre de ce droit, le site web&nbsp;peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;si les données à caractère personnel détenues par le site&nbsp;web&nbsp;sont inexactes, ils peuvent demander la mise à jour des informations, en écrivant à l'adresse électronique suivante&nbsp;: <span style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}><span className="boiler-field">contact@onfirstdigital.com</span></span>&nbsp;;</span></p>
          <p style={{}}><span style={{fontFamily: 'assistantBold'}}>-&nbsp;&nbsp;les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données, en écrivant à l'adresse électronique suivante&nbsp;: <span style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}><span className="boiler-field">contact@onfirstdigital.com</span></span>.<br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
              <br style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}} />
              <u><strong style={{ color:'#ffffff', textTransform:'uppercase'}}><span style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}>Evolution de la présente clause</span></strong></u></span></p>
        </div>
        <p><span style={{color: '#ffffff', fontStyle: 'normal', textAlign: 'left'}}><span style={{fontFamily: 'assistantBold'}}>Le site web&nbsp;se réserve le droit d'apporter toute modification à la présente clause relative à la protection des données à caractère personnel à tout moment. Si une modification est apportée à la présente clause de protection des données à caractère personnel, le site web&nbsp;s'engage à publier la nouvelle version sur son site. Le site web&nbsp;informera également les utilisateurs de la modification par messagerie électronique, dans un délai minimum de 15&nbsp;jours avant la date d'effet. Si l'utilisateur n'est pas d'accord avec les termes de la nouvelle rédaction de la clause de protection des données à caractère personnel, il a la possibilité de supp</span>rimer son compte.</span></p>
        <p dir="ltr"><span style={{display: 'none'}}>&nbsp;</span></p>
        </div>
         <FooterContainer />
            </>
            )}
        </themeContext.Consumer>)
}

export default Cgv;