import React from 'react';

const BusinessCard = ({src}) => {
    return(
    <>
        <img className="businessCard" src={src} />
    </>
    );
}

export default BusinessCard